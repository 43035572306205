<template>
  <div class="text-charcoal-grey bg-pure-white">
    <ViewHeadSection :description-text="$t('impressum.top_right_title_text')">
      <div class="w-full flex-no-shrink">
        <p class="font-sans-bold text-xl text-faded-red">{{ $t('impressum.top_left_title_text') }}</p>
        <p class="font-sans text-lg">{{ $t('impressum.top_right_title_text') }}</p>
        <p class="font-sans text-lg">Fabian Kölliker</p>
      </div>
    </ViewHeadSection>
    <div class="bg-pure-white mb-50">
      <div class="pl-39 pt-32">
          <p
              v-html="informations[$i18n.locale]"
              class="text-style-7 whitespace-pre-line leading-22"
          >
          </p>
      </div>
    </div>
    <p class="text-lg font-sans-bold pl-39">COPYRIGHT</p>
    <div
        class="flex text-base mt-17 pl-39"
        @click="openCopyright('https://www.swisskrono.de/Copyright')"
    >
      <p>{{ $t('impressum.copyright_open_link_text') }}</p>
      <IconArrow
          class="text-faded-red cursor-pointer ml-10"
          direction="right"
      />
    </div>
    <div class="bg-pure-white h-80"></div>
  </div>
</template>


<script>
import IconArrow from '@/components/icon/IconArrow';
import ViewHeadSection from '@/components/view/ViewHeadSection';

const email = '<a class="text-faded-red no-underline" href="mailto:info@swisskrono.com">' +
  'fabian.koelliker@swisskrono.com</a>';


export default {
  components: {
    IconArrow,
    ViewHeadSection,
  },
  data() {
    return {
      informations: {
        de: `SWISS KRONO Tec AG
            Museggstrasse 14
            6004 Luzern
            Schweiz
            UID-Nummer: CHE-101.028970

            Telefon: +41 (0)41 419 03 20
            Telefax: +41 (0)41 419 03 25

            E-Mail: ${email}

            Angaben gemäß § 5 TMG`,
        en: `SWISS KRONO Tec AG
            Museggstrasse 14
            6004 Luzern
            Switzerland
            UID-Number: CHE-101.028970

            Tel.: +41 (0)41 419 03 20
            Fax: +41 (0)41 419 03 25

            E-mail: ${email}

            This information complies with Art. 5 of the German Teleservices Act (TMG)`,
      },
    };
  },
  methods: {
    openCopyright(link) {
      window.open(link);
    },
  },
};
</script>


<style lang="scss" scoped>
</style>
